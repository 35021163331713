<template>
  <v-container>
    <!-- HEADING -->
    <h1 class="default-bottom-margin">{{ $t('news') }}</h1>

    <!-- NEW LIST -->
    <v-container
      v-for="i in chunkedNewsData[currentPage-1].length"
      :key="i"
    >
      <news-entry
        :date="chunkedNewsData[currentPage-1][i-1].date"
        :title="chunkedNewsData[currentPage-1][i-1]['title' + $i18n.locale]"
        :text="chunkedNewsData[currentPage-1][i-1]['content' + $i18n.locale]"
      />
    </v-container>

    <!-- PAGINATION -->
    <v-pagination 
      v-model="currentPage"
      :color="highlightColor"
      :length="chunkedNewsData.length"
      :total-visible="2"
      :prev-icon="icons.mdiMenuLeft"
      :next-icon="icons.mdiMenuRight"
    />
  </v-container>
</template>

<script>
import newsData from '@/assets/data/news-data.json';
import newsEntry from '@/components/news-entry.component.vue';
import styleVariables from '@/assets/scss/style-variables.scss';
import { mdiMenuLeft } from '@mdi/js';
import { mdiMenuRight } from '@mdi/js';

const chunk = require('chunk');

export default {
  name: 'News',
  metaInfo: {
    meta: [
      { name: 'description', content: 'Aktuelle Informationen über das Musikprojekt Synthetic Sympathy.'},
      { name: 'robots', content: 'index, follow'}
    ]
  },
  components: {
    newsEntry,
  },
  data: () => {
    return {
      currentPage: 1,
      newsData: newsData,
      newsPerPage: 10,
      highlightColor: styleVariables.highlightColor,
      icons: {
        mdiMenuLeft,
        mdiMenuRight,
      },
    }
  },
  computed: {
    chunkedNewsData() {
      return chunk(this.newsData, this.newsPerPage);
    }
  },
}
</script>